import React from 'react';
import Layout from '../components/Layout';

const RenderContent = (props) => (<div dangerouslySetInnerHTML={{__html:props.HTML}}></div>)


const Article = ({ pageContext }) => {
  return (
    <Layout>
      <div className = 'article'>
        <div className = 'article__image'>
          <img src = {pageContext.image} alt = '' />
        </div>
        <div className = 'article__details'>
          <div className = 'article__details_title'>
            {pageContext.title}
          </div>
          <div className = 'article__details_description'>
            {pageContext.description}
          </div>
          <RenderContent HTML = { pageContext.content }/>
        </div>
      </div>
    </Layout>
  );
}

export default Article
